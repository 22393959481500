import React from "react"

import Intro from "../components/intro"
import Layout from "../components/layout"
import RbMenu from "../components/menu"
import SEO from "../components/seo"

const About = () => {
  return (
    <Layout isHomePage>
      <SEO title="About Me" />
      <RbMenu />
      <Intro />
    </Layout>
  )
}

export default About
