/**
 * Intro message
 */

import React from "react"


const Intro = () => {
 

  return (
    <div className="intro">
      <h1>Hello! I’m Maruthi</h1>
      <p>I’m a Web Designer from Hyderabad. Also a Graphic Designer, a UI Designer, a Music Addict and an Idiot.</p>
      <h2>The Who</h2>
      <p>They call me the&nbsp;<a href="https://www.youtube.com/watch?v=Wbt59pmT26E" target="_blank">RobotBoy</a>, or I&nbsp;<em><s>make them</s></em>&nbsp;implore them to.</p>
      <p>I’m a UI designer who knows how to code &#8211; so I can plan, design and develop smooth and responsive websites for you. I’m also a graphic designer, so I can design logos, posters or any other branding material too.</p>
      <p>I’ve been freelancing for more than 8 years (with my awesome friends at&nbsp;<a href="http://citrusdesigns.in/">Citrus Designs</a>), helping out budding start-ups in building a brand and already successful brands in revamping their identity.</p>
      <h2>The How</h2>
      <ul>
        <li>Adobe Illustrator and Photoshop for graphic designing</li>
        <li>Adobe XD for UI design</li>
        <li>HTML, CSS and Javascript (+ jQuery and React) for Front-End</li>
        <li>PHP, NodeJs (+ ExpressJS), SQL and MongoDB for Back-End</li>
      </ul>
      <h2>The Why</h2>
      <p>Because I’m obsessed with Colors, Typography and the Web</p>
      <h2>The &#8216;Line in the Sand&#8217;</h2>
      <p>Although I’m not a formalist, I do have a few rules that I wouldn&#8217;t like to break</p>
      <ul>
        <li>I will not make you a hamburger list icon with just two lines –&nbsp;<em>because a list needs 3 items at least and a hamburger needs three layers</em></li>
        <li>I will not use flash –&nbsp;<em>because it’s dead</em></li>
        <li>I will not charge you for a non-profit project –&nbsp;<em>because you are an awesome human being</em></li>
      </ul>

    </div>
  )
}

export default Intro
